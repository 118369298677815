<template>
  <div>
    <!-- <HeaderComp1 /> -->
    <section id="partners-page" class="content-height">
      <!--<InternalNavbar />-->
      <PageLoader />
      <div class="hr"></div>
      <div class="container-fluid">
        <!-- <div class="titleS">
          <span>{{ $t("title.partners") }}</span>
        </div> -->
        <div class="content">
          <div class="row justify-content-center">
            <h1 style="margin-top:18vw">{{$t("not_found")}}</h1>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
// import HeaderComp1 from "@/components/HeaderComp1.vue";
//import InternalNavbar from "@/components/InternalNavbar.vue";
export default {
  components: {
    PageLoader,
    //HeaderComp1,
  //InternalNavbar
  },
  data: function() {
    return {
    };
  },
};
</script>
